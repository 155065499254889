.tower-image {
  height: 400px;
  border: 1px solid lightgrey;
  border-radius: 6px;
}

@media only screen and (max-width: 600px) {
  .tower-image {
      height: 280px;
  }
}