.dumbbell-image {
  height: 245px;
  border: 1px solid lightgrey;
  border-radius: 6px;
}

@media only screen and (max-width: 600px) {
  .dumbbell-image {
      height: 171px;
  }
}
