.wheel-image {
  height: 254px;
  border: 1px solid lightgrey;
  border-radius: 6px;
}

@media only screen and (max-width: 600px) {
  .wheel-image {
      height: 178px;
  }
}
