.navbar-container {
    background-color: #FAF9F6;
    z-index: 3;
    top: 0;
    position: sticky;
    width: 100%;
    box-shadow: 0 2px 0 0 lightgray;
    display: flex;
    padding: 26px 0;
    width: 100%;
}

.navbar-icon {
    height: 20px;
    width: 20px;
}

.navbar-header {
    margin: 0;
    margin-right: 30px;
    font-size: 28px;
}

.navbar-header-icon {
    background: #096ffa;
    color: white;
    height: 34px;
    line-height: 34px;
    padding: 0 2px;
    margin-right: 2px;
}

.header-link {
    text-decoration: none;
    color: black;
    opacity: 1;
    display: flex;
}

.main-link {
    text-decoration: none;
    color: black;
    opacity: .8;
}

.main-link:hover {
    opacity: 1;
    text-decoration: underline;
}

.navbar-header-main {
    display: flex;
}

.navbar-header-main-link {
    margin: 0 20px;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
    color: black;
    opacity: .8;
}

.navbar-hamburger {
    display: none;
}

.navbar-header-container {
    margin-left: 60px;
}

.drawer-container {
    height: 100vh;
    width: 300px;
    background-color: white;
    transition: all 0.25s ease-in-out;
    transform: translate(-300px);
    position: absolute;
    top: 0;
    box-shadow: 0 2px 100px 0 rgb(17 17 17 / 50%);
}

.drawer-container--isOpen {
    transform: translate(0);
}

.drawer-close-container {
    display: flex;
    margin-top: 20px;
}

.drawer-close-header {
    width: 100%;
    padding: 10px;
    font-size: 20px;
    margin-top: 3px;
    padding-left: 20px;
}

.drawer-close {
    float: right;
    padding: 10px;
    padding-right: 20px;
    font-size: 24px;
}

.drawer-close-btn {
    cursor: pointer;
}

.drawer-close-btn:hover {
    color: #096ffa;
}

.drawer-nav {
    padding: 30px;
}

.drawer-nav-link {
    margin-bottom: 30px;
}

.drawer-link:hover {
    text-decoration-color: #096ffa;
}

@media only screen and (max-width: 1400px) {
    .navbar-hamburger {
        margin-right: 30px;
        margin-left: 24px;
        display: block;
        cursor: pointer;
    }

    .navbar-header-main {
        display: none;
    }

    .navbar-header-container {
        margin-left: 0;
    }

    .navbar-header {
        font-size: 20px;
        margin-right: 10px;
    }

    .navbar-header-icon {
        font-size: 14px;
        height: 24px;
        line-height: 24px;
    }
}