
.treadmill-image {
    height: 400px;
    border: 1px solid lightgrey;
    border-radius: 6px;
}

@media only screen and (max-width: 600px) {
    .treadmill-image {
        height: 300px;
    }
}