.bike-image {
    height: 400px;
    border: 1px solid lightgrey;
    border-radius: 6px;
}
  
@media only screen and (max-width: 600px) {
    .bike-image {
        height: 280px;
    }
}
