.badroute-container {
    margin: 30px;
    text-align: center;
    margin-bottom: 100px;
}

.badroute-image {
    width: 600px;
    border: 1px solid lightgrey;
    border-radius: 6px;
}

.badroute-header {
    font-size: 30px;
    margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {
    .badroute-container {
        margin: 30px 10px;
        margin-bottom: 60px;
    }

    .badroute-image {
        width: 100%;
    }
}