.home-container {
  color: black;
}

.home-text-container {
  padding: 20px;
  margin-left: 200px;
}

.home-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.last-updated {
  opacity: .8;
  font-style: italic;
}

.home-header-container {
  display: flex;
  margin: 30px 0;
}

.home-header {
  font-size: 38px;
  margin: 0;
  margin-top: 14px;
}

.home-divider {
  color: #096ffa;
  font-weight: 500;
  font-size: 64px;
}

.paragraph {
  font-weight: 300;
  font-style: normal;
  font-size: 19px;
  line-height: 1.5;
  color: #000;
  letter-spacing: .8px;
  letter-spacing: 0;
  line-height: 1.8125;
  margin-top: 0;
  margin-bottom: 32px;
  max-width: 1000px;
}

.main-image {
  width: 400px;
  margin: 30px 0 40px 0;
}

.outside-link {
  text-decoration: none;
  color: #096ffa;
  font-weight: 400;
  margin-left: 4px;
}

@media only screen and (max-width: 600px) {
  .home-image {
    height: 200px;
  }

  .home-header {
    font-size: 32px;
  }

  .home-divider {
    font-size: 58px;
  }

  .last-updated {
    font-size: 16px;
    margin: 0;
  }

  .home-header-container {
    margin-top: 20px;
  }

  .main-image {
    width: 280px;
    margin-top: 20px;
  }

  .paragraph {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1400px) {
  .home-text-container {
    margin: 0;
  }
}