.number-header {
    margin-top: 70px;
    font-size: 25px;
    font-weight: 600;
}

.target-muscles-container {
    margin-top: 20px;
    margin-bottom: 40px;
}

.target-muscles {
    font-weight: 600;
}

.first-number-header {
    margin-top: 48px;
}

.workout-image {
    margin: 30px 0 40px 0;
    width: 100%;
    height: auto;
    max-width: 750px;
    border: 1px solid lightgrey;
    border-radius: 6px;
}

.video-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 750px;
}
  
.video-container::after {
    display: block;
    content: "";
    padding-top: 56.25%;
}
  
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 700px) {
    .workout-image {
        margin-top: 20px;
    }
}